import './App.css';
import Hero from './pages/Hero.tsx';
import ourservice13 from "./images/ourservice13.png";
import ourservice14 from "./images/ourservice14.png";
import ourservice3 from "./images/ourservice3.webp";
import ourservice4 from "./images/ourservice4.png";
import ourservice1 from "./images/ourservice1.jpg.webp";
import { Translate } from './Translation.js';
import {useEffect, useRef} from "react";
function App() {
  const cardsRef = useRef([]);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('in-view');
        } else {
          entry.target.classList.remove('in-view');
        }
      });
    }, { threshold: 0.1 });

    cardsRef.current.forEach(card => {
      observer.observe(card);
    });

    return () => {
      cardsRef.current.forEach(card => {
        observer.unobserve(card);
      });
    };
  }, []);
  return (
    <main className="h-full w-full">
      <div className="flex flex-col gap-20">
        <Hero />
        <section className='row p-4' id="services">
        {[ourservice1, ourservice3, ourservice4,  ourservice13, ourservice14].map((service, index) => (         
            <section key={index}
              className="card col-12 p-4 d-flex flex-md-row justify-content-between align-items-center mt-3" style={{backgroundColor:"transparent"}}
              ref={el => cardsRef.current[index] = el}
            >
              <section className='col-6'>
                <section className="card-title text-black no-shadow text-white p-3">
                  {Translate(`WorkForceImg${index + 1}`)[0]}
                </section>
                <section className="card-text text-black no-shadow text-white p-3">
                  {Translate(`WorkForceImg${index + 1}`)[1]}
                </section>
              </section>
              <section className='mt-1'>
                <img src={service} alt="service" style={{height: "250px", width:"300px", objectFit:"cover"}}/>
              </section>
            </section>
        ))}
      </section>
      </div>
    </main>
  );
}

export default App;
